import React, { useEffect, useState } from 'react';
import { ServerStatus } from '../../api';
import Spinner from '../../components/Spinner';
import ServerStatusItem from './ServerStatusItem';

type ServerStatusListProps = {
  data: ServerStatus[] | undefined;
  isLoading: boolean;
  expanded: boolean;
};

type GroupedServers = {
  [key: string]: {
    [key: string]: ServerStatus;
  };
};

const ServerStatusList = ({
  data,
  isLoading,
  expanded,
}: ServerStatusListProps) => {
  const [groupedData, setGroupedData] = useState<GroupedServers | null>(null);

  useEffect(() => {
    if (data) {
      const gd: GroupedServers = {};

      data.forEach(d => {
        const keys = Object.keys(gd);
        if (!keys.includes(d.name)) {
          gd[d.name] = {};
        }
        gd[d.name][d.port] = { ...d };
      });

      setGroupedData(gd);
    }
  }, [data]);

  if (!data && !isLoading) return <div>No data!</div>;
  if (isLoading) return <Spinner />;

  return (
    <>
      <ul>
        {groupedData &&
          Object.entries(groupedData).map(([server, port]) => {
            const portData = Object.values(port);

            const isActive = Object.values(port).some(
              p => p.status === 'active'
            );

            return (
              <ServerStatusItem
                server={server}
                status={isActive}
                data={portData}
                key={server}
                expanded={expanded}
              />
            );
          })}
      </ul>
    </>
  );
};

export default ServerStatusList;
