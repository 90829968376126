import React, { useState } from 'react';
import styled from 'styled-components';
import { PageTemplate } from '../../components/PageTemplate';
import { Heading } from '../../components/Heading';
import { useGetStatus } from '../../api/hooks';
import ServerStatusList from './ServerStatusList';
import { Button } from '../../components/Button';

const SectionTitle = styled(Heading)`
  margin-top: 30px;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
`;

const ExpandButton = styled(Button)`
  padding: 4px;
`;

export const Monitoring = () => {
  const { data, isLoading } = useGetStatus();
  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleExpanded = () => setExpanded(prevState => !prevState);

  return (
    <PageTemplate title="Monitoring">
      <section>
        <SectionTitle level={3} className="mb5">
          Server list status
        </SectionTitle>
        {data && (
          <ExpandButton
            label={`${expanded ? 'Shrink all' : 'Expand all'}`}
            onClick={toggleExpanded}
          />
        )}
        <ServerStatusList
          data={data}
          isLoading={isLoading}
          expanded={expanded}
        />
      </section>
    </PageTemplate>
  );
};
