import React, { useEffect, useMemo, useState } from 'react';
import { Checkmark, Close, Down, Next } from 'grommet-icons';
import { Table, TableBody, TableCell, TableHeader, TableRow } from 'grommet';
import styled from 'styled-components';
import { ServerStatus } from '../../api';

type ServerStatusItemProps = {
  server: string;
  status: boolean;
  data: ServerStatus[];
  expanded: boolean;
};

const BodyTableCell = styled(p => <TableCell {...p} />)`
  padding-top: 0;
  padding-bottom: 0;
`;

const ServerData = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const IconButton = styled.button`
  background: none;
  border: none;
`;

const ServerStatusItem = ({
  server,
  status,
  data,
  expanded,
}: ServerStatusItemProps) => {
  const [showPorts, setShowPorts] = useState<boolean>();

  useEffect(() => {
    setShowPorts(expanded);
  }, [expanded]);

  const getIcon = useMemo(() => {
    if (status) {
      return <Checkmark size="small" color="green" />;
    }

    return <Close size="small" color="red" />;
  }, [status]);

  const toggleDropdown = () => setShowPorts(prevState => !prevState);

  const renderPorts = () => {
    if (!showPorts) return null;

    const getIconWithStatus = (portStatus: 'active' | 'inactive') => {
      if (portStatus === 'active') {
        return <Checkmark size="small" color="green" />;
      }

      return <Close size="small" color="red" />;
    };

    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell size="small" scope="col">
              Port
            </TableCell>
            <TableCell size="small" scope="col">
              Status
            </TableCell>
            <TableCell size="small" scope="col">
              Chain id
            </TableCell>
            <TableCell size="small" scope="col">
              Chain id (dec)
            </TableCell>
            <TableCell size="small" scope="col">
              Latest Block
            </TableCell>
            <TableCell size="small" scope="col">
              no. Of Tx
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map(p => (
            <TableRow key={`${server}_${p.port}`}>
              <BodyTableCell size="small" scope="col">
                {p.port}
              </BodyTableCell>
              <BodyTableCell size="small" scope="col">
                {getIconWithStatus(p.status)}
              </BodyTableCell>
              <BodyTableCell size="small" scope="col">
                {p.status === 'active' && p.data.result}
              </BodyTableCell>
              <BodyTableCell size="small" scope="col">
                {p.status === 'active' && p.data.latestBlock?.blockNumber}
              </BodyTableCell>
              <BodyTableCell size="small" scope="col">
                {p.status === 'active' && p.data.latestBlock?.txCount}
              </BodyTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <li>
      <ServerData>
        {server}: {getIcon}
        <IconButton onClick={toggleDropdown}>
          {showPorts ? <Next size="small" /> : <Down size="small" />}
        </IconButton>
      </ServerData>

      {renderPorts()}
    </li>
  );
};

export default ServerStatusItem;
