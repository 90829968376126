import axios, { AxiosResponse } from 'axios';

type RPCResponse = {
  id: number;
  jsonrpc: string;
  result: string;
  chainIdDec: number;
  latestBlock?: {
    blockNumber: number;
    txCount: number;
  };
};

type ServerStatusBase = {
  name: string;
  ip: string;
  port: string;
  url: string;
  status: 'active' | 'inactive';
  ts: Date;
};

type ServerStatusActive = ServerStatusBase & {
  status: 'active';
  data: RPCResponse;
};

type ServerStatusInactive = ServerStatusBase & {
  status: 'inactive';
  error: string;
};

export type ServerStatus = ServerStatusActive | ServerStatusInactive;

export const getStatus = async () => {
  const res = (await axios.get(`/api`)) as AxiosResponse<ServerStatus[]>;

  return res.data;
};
